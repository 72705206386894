import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { Script } from 'gatsby';

import BlockStyles from '../../BlockStyles';
import ButtonLink from '../../ButtonLink';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const WidgetContainer = styled.div`
  margin: 1rem 0 1.25rem;

  > * {
    background: ${prop('theme.colors.white')};
    border-radius: 1.5rem;
    border: 1px solid ${prop('theme.colors.borderGrey')};
    display: flex;
    padding: 0.5rem 1.25rem;
    width: 100%;

    a.basic {
      display: block;
    }
  }
`;

const TextContainer = styled.span`
  text-transform: uppercase;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  letter-spacing: 1px;
  padding: 0.5rem;
`;

const propTypes = {
  buttonLink: PropTypes.object,
  buttonText: PropTypes.string
};

const defaultProps = {
  buttonLink: {},
  buttonText: ''
};

function CurrentStatus({ buttonLink, buttonText }) {
  const url = buttonLink.url;

  return (
    <>
      {/* These scripts are for the statuspage-widget web component.
      https://bitbucket.org/atlassian/statuspage-status-widget */}
      <Script
        key='https://cdn.polyfill.io/v3/polyfill.min.js?features=Promise,es6,fetch,smoothscroll'
        src='https://cdn.polyfill.io/v3/polyfill.min.js?features=Promise,es6,fetch,smoothscroll'
      />
      <Script
        key='https://unpkg.com/@webcomponents/webcomponentsjs@2.1.3/webcomponents-bundle.js'
        src='https://unpkg.com/@webcomponents/webcomponentsjs@2.1.3/webcomponents-bundle.js'
      />
      <Script
        key='https://unpkg.com/@statuspage/status-widget/dist/index.js'
        src='https://unpkg.com/@statuspage/status-widget/dist/index.js'
      />
      <BlockStyles>
        <div className='block'>
          <WidgetContainer>
            <statuspage-widget
              appearance='none'
              src='https://status.thesmallexchange.com/'
            ></statuspage-widget>
          </WidgetContainer>
          <ButtonContainer>
            {url && (
              <ButtonLink link={{ url: url, target: buttonLink.target }}>
                <TextContainer>{buttonText}</TextContainer>
              </ButtonLink>
            )}
          </ButtonContainer>
        </div>
      </BlockStyles>
    </>
  );
}

CurrentStatus.propTypes = propTypes;
CurrentStatus.defaultProps = defaultProps;

export default CurrentStatus;
